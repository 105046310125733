<template>
  <el-dialog :title="title" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form :rules="rules" ref="ruleForm" :model="form" label-width="120px" :disabled="isDisable">
      <el-form-item label="虫子名称" prop="pestName">
        <el-input v-model="form.pestName"></el-input>
      </el-form-item>
      <el-form-item label="虫子图片" prop="fileList">
        <el-upload
          ref="upload"
          :class="{ hideUpload: hideUploadEdit }"
          action=""
          :file-list="form.fileList"
          :limit="1"
          list-type="picture-card"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="活跃时间段" prop="monthData">
        <el-date-picker v-model="form.monthData" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="说明" prop=" disasterDescription">
        <el-input type="textarea" autosize v-model="form.disasterDescription"></el-input>
      </el-form-item>
      <el-form-item label="预防方案" prop=" preventionProgram">
        <el-input type="textarea" autosize v-model="form.preventionProgram"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { create, update } from '@/api/insectAttack/api';
import dayjs from 'dayjs';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: {},
    isDisable: false,
    machineType: []
  },
  components: {},
  computed: {
    title() {
      let state = '新建';
      if (this.isEdit) {
        state = '编辑';
      }
      return state;
    }
  },
  data() {
    return {
      hideUploadEdit: false,
      rules: {
        pestName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        monthData: [{ required: true, message: '请选择月份', trigger: 'blur' }],
        machineType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        fileList: [{ required: true, message: '请选择图片', trigger: 'blur' }]
      },
      fileList: [],
      monthData: [],
      form: {
        pestName: '',
        machineType: '',
        fileList: [],
        monthData: '',
        disasterDescription: '',
        preventionProgram: '',
        factoryName: '',
        code: ''
      }
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        this.form = {
          pestName: '',
          machineType: '',
          fileList: [],
          monthData: '',
          disasterDescription: '',
          preventionProgram: '',
          factoryName: '',
          code: ''
        };
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.form.fileList = fileList;
    },
    getDetail() {
      let res = _.cloneDeep(this.currentId);
      this.form = _.cloneDeep(this.currentId);
      if (res.activeTimeStart && res.activeTimeEnd) {
        this.form.monthData = [new Date(res.activeTimeStart), new Date(res.activeTimeEnd)];
      }
      this.form.fileList = [{ name: '1', url: this.form.url }];
      if (this.fileList && this.fileList.length) {
        this.hideUploadEdit = true;
      }
    },
    closeMe(done) {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((val) => {
        if (!val) return;

        let formData = new FormData();
        if (this.form.fileList && this.form.fileList.length && this.form.fileList[0].raw) {
          formData.append('file', this.form.fileList[0].raw);
        }

        let params = {};
        params.activeTimeStart = this.form.monthData[0] ? dayjs(this.form.monthData[0]).format('YYYY-MM-DD HH:mm:ss') : '';
        params.activeTimeEnd = this.form.monthData[1] ? dayjs(this.form.monthData[1]).format('YYYY-MM-DD HH:mm:ss') : '';
        params.disasterDescription = this.form.disasterDescription;
        params.preventionProgram = this.form.preventionProgram;
        params.pestName = this.form.pestName;
        if (this.isEdit) {
          params.id = this.form.id;
        }
        formData.append('requestJson', JSON.stringify(params));

        if (this.isEdit) {
          update(formData)
            .then((e) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else {
          create(formData)
            .then((e) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        }
      });
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss"></style>
