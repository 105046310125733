<!-- //科技管理 -->
<template>
  <div>
    <div class="bottom12">
      <el-row :gutter="20">
        <el-col :span="4" class="flex-row">
          <span class="span">地块名称</span>
          <el-select size="mini" filterable style="flex: 1" clearable v-model="searchParams.landId" placeholder="请选择地块名称" @change="landChange">
            <el-option v-for="item in plantLands" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col class="search-item" :span="6">
          <div class="titile">种植作物:</div>
          <el-select size="mini" v-model="searchParams.cropsACategoryId" placeholder="请选择" @change="onOptionChange" clearable>
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
          <el-select size="mini" v-model="searchParams.cropsAId" placeholder="请选择" clearable>
            <el-option v-for="item in categorySubList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="4" class="flex-row">
          <span class="span">操作人</span>
          <el-select size="mini" style="flex: 1" clearable v-model="searchParams.operatorId" placeholder="请选择操作人" @change="userChange">
            <el-option v-for="item in sysUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="flex-row">
          <span class="span">巡田时间</span>
          <el-date-picker
            size="mini"
            style="flex: 1"
            v-model="searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-col>
        <el-col :span="2" class="flex-row">
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column label="种植历史">
        <template slot-scope="scope"> {{ transHistoryDate(scope.row.startDate) }} - {{ transHistoryDate(scope.row.endDate) }} </template>
      </el-table-column>
      <el-table-column prop="landName" label="地块名称"> </el-table-column>
      <!-- <el-table-column prop="landArea" label="地块面积(亩)">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.landArea) }}</template>
      </el-table-column> -->
      <el-table-column label="种植面积(亩)">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.plantArea) }}</template>
      </el-table-column>
      <el-table-column prop="cropsACategoryName" label="种植作物"> </el-table-column>
      <el-table-column prop="cropsAName" label="作物品种"> </el-table-column>
      <el-table-column prop="farmingTypeName" label="巡田时间" width="200">
        <template slot-scope="scope"> {{ scope.row.realBeginDate | filterDate }}-{{ scope.row.realEndDate | filterEndDate }} </template>
      </el-table-column>
      <el-table-column prop="operatorName" label="操作人"> </el-table-column>
      <el-table-column label="巡田轨迹">
        <template slot-scope="scope">
          <el-button slot="reference" type="text" size="small" @click="toGuiji(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="巡田类型">
        <template slot-scope="scope">
          {{
            scope.row.taskType == 'simpleTask'
              ? '普通巡田'
              : scope.row.taskType == 'schemeTask' || scope.row.taskType == 'temporaryTask'
              ? '任务巡田'
              : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toDetail(scope.row)">诊断方案</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <patrolDetail :detailId="detailId" :patrolVisible.sync="showDk"></patrolDetail>
    <questionDialog :dialogVisible.sync="dialogVisible" :isEdit="isEdit" :currentId="currentId" @updateList="getList"> </questionDialog>
  </div>
</template>

<script>
import { warmList } from '@/api/insectAttack/api.js';
import questionDialog from './questionDialog.vue';
import { plantLands, sysUsers, getRequest } from '@/api/api.js';
import patrolDetail from '@/components/patrolDetail';
import dayjs from 'dayjs';

import * as utils from '@/utils/utils.js';
export default {
  components: {
    questionDialog,
    patrolDetail
  },
  props: {},
  data() {
    return {
      detailId: '',
      showDk: false,
      dialogVisible: false,
      searchDate: [],
      searchParams: {
        cropsAId: '',
        cropsACategoryId: ''
      },

      userId: '',
      landId: '',
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      isEdit: false,
      tableData: [],
      currentId: '',
      sysUsers: [],
      plantLands: [],
      categorySubList: [],
      categoryList: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getPlantLands();
    this.getSysUsers();
    this.getCategoryList();
  },
  filters: {
    filterDate(val) {
      if (!val) {
        return '';
      }
      return dayjs(val).format('YYYY.MM.DD  HH:mm');
    },
    filterEndDate(val) {
      if (!val) {
        return '';
      }
      return dayjs(val).format('  HH:mm');
    }
  },
  methods: {
    transHistoryDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD');
      } else {
        return '至今';
      }
    },
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    onOptionChange(id) {
      console.log('###@@@', this.searchParams);
      this.searchParams.cropsAId = '';

      console.log('###@@@', this.searchParams);
      this.categoryList.map((e) => {
        if (id == e.id) {
          this.categorySubList = e.species || [];
          // if (e.species && e.species.length) {
          //   this.searchParams.cropsAId = e.species[0].id;
          // }
        }
      });
    },
    getCategoryList() {
      this.categoryList = [];
      getRequest('/api/operate/planting/scheme/category/list', {})
        .then((res) => {
          this.categoryList = res;
        })
        .catch((error) => {});
    },
    toGuiji(item) {
      this.detailId = item.id;
      this.showDk = true;
    },
    getSysUsers() {
      sysUsers({})
        .then((res) => {
          this.sysUsers = res;
        })
        .catch((error) => {});
    },
    getPlantLands() {
      plantLands({})
        .then((res) => {
          this.plantLands = res;
        })
        .catch((error) => {});
    },
    toDetail(item) {
      this.currentId = item;
      this.isEdit = false;
      this.dialogVisible = true;
    },
    getList(pageNum) {
      if (pageNum) {
        this.pageInfo.currentPage = pageNum;
      }
      warmList({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ...this.searchParams,

        startDate: this.searchDate && this.searchDate[0] ? dayjs(this.searchDate[0]).format('YYYY-MM-DD') : '',
        endDate: this.searchDate && this.searchDate[1] ? dayjs(this.searchDate[1]).format('YYYY-MM-DD') : ''
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },

    userChange(val) {
      this.userId = val;
    },
    landChange(val) {
      this.landId = val;
    },
    search() {
      // this.searchParams = {
      //   farmingTypeId: this.farmingTypeId,
      //   ownerId: this.userId,
      //   landId: this.landId,
      // };
      this.pageInfo.currentPage = 1;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
@import '@/assets/common.scss';
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span {
    font-size: inherit;
    font-size: 12px;
    width: 60px;
  }
}
.bottom12 {
  margin-bottom: 12px;
}
</style>
