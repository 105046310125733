<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">虫子名称</span>
        <el-input v-model="searchParams.pestName" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="10" class="flex-row">
        <span class="span1">活跃时间段</span>
        <el-date-picker
          v-model="searchParams.monthData"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="dateChange"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <!-- <el-button type="primary" size="small" @click="(isEdit = false), (isDisable = false), (dialogVisible = true)">新增</el-button> -->
    </el-row>
    <el-table :data="tableData" style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="pestName" label="虫子名称"> </el-table-column>
      <el-table-column prop="title" label="图片">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.url"
            style="width: 100px; height: 100px"
            :src="scope.row.url"
            :zoom-rate="1.2"
            :preview-src-list="[scope.row.url]"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="activeTimeStart" label="活跃开始时间">
        <template slot-scope="scope">
          {{ formDay(scope.row.activeTimeStart) }}
        </template>
      </el-table-column>
      <el-table-column prop="activeTimeEnd" label="活跃结束时间" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ formDay(scope.row.activeTimeEnd) }}
        </template>
      </el-table-column>
      <el-table-column prop="disasterDescription" show-overflow-tooltip label="说明"> </el-table-column>
      <el-table-column prop="preventionProgram" show-overflow-tooltip label="预防方案"> </el-table-column>

      <!-- <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toEdit(scope.row)">修改</el-button>
          <el-popconfirm title="确认删除吗？" @confirm="toDelete(scope.row)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <create
      :dialogVisible.sync="dialogVisible"
      :machineType="machineType"
      :isDisabled="isDisable"
      :isEdit="isEdit"
      :currentId="currentId"
      @updateList="getList"
    ></create>
  </div>
</template>

<script>
import { dataList, dataDelete } from '@/api/insectAttack/api.js';
import create from './create.vue';
import dayjs from 'dayjs';
export default {
  components: {
    create
  },
  data() {
    return {
      dicAll: {},
      dialogVisible: false,
      isDisable: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },

      activeName: '1',

      tableData: [],
      machineType: [],
      questionTypeDic: null
    };
  },
  created() {},
  async mounted() {
    this.getList();
    this.machineType = await this.$store.dispatch('getDicOptionsList', 'machine.type');
  },
  methods: {
    formDay(item) {
      return dayjs(item).format('YYYY-MM-DD');
    },
    getMachineType(id) {
      let res = '';
      this.machineType.map((e) => {
        if (e.id == id) {
          res = e.name;
        }
      });
      return res;
    },
    toDelete(item) {
      dataDelete({ id: item.id })
        .then((res) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },

    toEdit(item) {
      this.isDisable = false;
      this.currentId = item;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      let params = {};
      if (this.searchParams.monthData && this.searchParams.monthData.length) {
        params.activeTimeStart = this.searchParams.monthData[0] ? dayjs(this.searchParams.monthData[0]).format('YYYY-MM-DD HH:mm:ss') : '';
        params.activeTimeEnd = this.searchParams.monthData[1] ? dayjs(this.searchParams.monthData[1]).format('YYYY-MM-DD HH:mm:ss') : '';
      }
      params.pestName = this.searchParams.pestName;
      dataList({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...params })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
